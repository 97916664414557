<template>
  <el-container class="cash-register-main">
    <el-aside width="415px" class="purchaser-info">
      <el-container>
        <el-header height="42px">
          <el-form :inline="true">
            <el-form-item label="客户">
              <el-input v-model="purchaser.searchForm.searchValue" class="search-name" @keydown.enter.native="getPurList"
                placeholder="客户名称" clearable></el-input>
            </el-form-item>
            <el-form-item label="欠款">
              <el-input v-model="purchaser.searchForm.debtFeeRange.Key" class="search-fee"
                @keydown.enter.native="getPurList" placeholder="最小值" v-format.float></el-input>
            </el-form-item>
            <el-form-item label="-">
              <el-input v-model="purchaser.searchForm.debtFeeRange.Value" class="search-fee"
                @keydown.enter.native="getPurList" placeholder="最大值" v-format.float></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" icon="el-icon-refresh" @click="getPurList"></el-button>
              <el-button type="primary" icon="el-icon-upload2" :loading="exportLoading"
                @click="exportData(0)"></el-button>
            </el-form-item>
          </el-form>
        </el-header>
        <el-main>
          <el-table ref="purchaser" :data="purchaser.list" :row-class-name="handleRowClassName" border
            @row-click="selectPurchaser" height="100%" v-el-table-infinite-scroll="getPurNextList"
            :infinite-scroll-disabled="purchaser.loading || purchaser.list.length >= purchaser.total.count">
            <el-table-column type="index" label="序号" width="50" :fixed="false" align="center"></el-table-column>
            <el-table-column property="Name" label="客户" width="150" align="center" show-overflow-tooltip>
              <template slot-scope="{ row }">
                {{ (row.Nick ? '(' + row.Nick + ')' : '') + row.Name }}
              </template>
            </el-table-column>
            <el-table-column property="DebtFee" label="欠款金额" align="center">
              <template slot-scope="{ row }">
                ￥{{ row.DebtFee | decimalFormat }}
              </template>
            </el-table-column>
          </el-table>
        </el-main>
        <el-footer height="60px" class="statistics">
          <div>{{ purchaser.total.count }}</div>
          <div>{{ purchaser.total.debtCount }}</div>
          <div>￥{{ purchaser.total.debtFee | decimalFormat }}</div>
          <div>客户数</div>
          <div>欠款客户数</div>
          <div>合计金额</div>
        </el-footer>
      </el-container>
    </el-aside>
    <el-main class="record-list">
      <el-container>
        <el-header height="80px">
          <el-row>
            <el-col :span="5">
              <div style="text-align: center">
                <el-tooltip placement="top-start">
                  <div slot="content">点击人像<br />可编辑 / 查看不同客户之间的关系映射</div>
                  <span style="cursor: pointer" @click="showPurchaserMapping(purchaser.selectRow)">
                    <img
                      :src="`/static/img/userset${purchaser.selectRow && purchaser.selectRow.ParentPurchaserID ? '1' : ''}.png`" />
                    <span v-if="purchaser.selectRow" :title="'昵称：' + purchaser.selectRow.Nick">{{
                      purchaser.selectRow.Nick }}</span><br />
                    <span v-if="purchaser.selectRow" style="font-size: 18px;font-weight: bold;line-height: 40px;"
                      :title="purchaser.selectRow.Name">{{ purchaser.selectRow.Name }}</span>
                  </span>
                </el-tooltip>
              </div>
            </el-col>
            <el-col :span="19">
              <el-form :inline="true">
                <el-form-item label="操作时间：">
                  <el-date-picker v-model="dateDebtRange" type="daterange" :clearable="true" range-separator="至"
                    start-placeholde="开始日期" end-placeholde="结束日期" value-format="yyyy-MM-dd" class="date-range"
                    @change="getBillList" clear-icon="el-icon-error" :picker-options="datePickerOptions"
                    :disabled="!purchaser.selectRow"></el-date-picker>
                </el-form-item>
                <el-form-item label="类型：">
                  <el-select v-model="purchaser.bill.searchForm.type" @change="getBillList"
                    :disabled="!purchaser.selectRow">
                    <el-option label="全部" value=""></el-option>
                    <el-option label="销售单" value="trade"></el-option>
                    <el-option label="退货单" value="refund"></el-option>
                    <el-option label="欠款单" value="credit"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="操作：">
                  <el-select v-model="purchaser.bill.searchForm.operation" @change="getBillList"
                    :disabled="!purchaser.selectRow">
                    <el-option label="全部" value=""></el-option>
                    <el-option label="欠款" value="credit"></el-option>
                    <el-option label="提货" value="take"></el-option>
                    <el-option label="退货" value="refund"></el-option>
                    <el-option label="代发" value="proxy"></el-option>
                    <el-option label="还款" value="repay"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="屏蔽撤回：">
                  <el-tooltip effect="dark" content="屏蔽撤回" placement="top-start">
                    <el-switch v-model="purchaser.bill.searchForm.blockRecall" @change="getBillList"
                      :disabled="!purchaser.selectRow"></el-switch>
                  </el-tooltip>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" @click="getBillList" :disabled="!purchaser.selectRow">查询</el-button>
                  <el-button type="primary" @click="exportData(1)" :loading="exportDetailLoading"
                    :disabled="!purchaser.selectRow">导出</el-button>
                </el-form-item>
                <el-form-item style="float: right">
                  <el-button type="success" @click="showRepayDlg" :disabled="!purchaser.selectRow">还款</el-button>
                </el-form-item>
                <el-form-item style="float: right">
                  <el-button type="primary" plain @click="showCreditTradeEdit" style="display: inline-block"
                    :disabled="!purchaser.selectRow">新增欠款</el-button>
                </el-form-item>
              </el-form>
            </el-col>
          </el-row>
        </el-header>
        <el-main>
          <el-table ref="bill" :data="purchaser.bill.list" @row-click="handleBillRowClick" border
            :tree-props="{ children: 'Childrens' }" highlight-current-row @selection-change="handleBillSelectionChange"
            @select="handleBillSelectRow" :row-class-name="handleRowClassName" height="100%"
            v-el-table-infinite-scroll="getNexBillList" row-key="RowKey"
            :infinite-scroll-disabled="purchaser.bill.loading || purchaser.bill.list.length >= purchaser.bill.total.count">
            <el-table-column type="selection" width="25"
              :selectable="(row) => row.Type != 'credit' && !row.FlagDel && row.Operation != 'recall'" />
            <el-table-column property="TypeCreateTime" label="报单时间" width="126">
              <template slot-scope="{ row }">
                <span v-if="row.Childrens && row.Childrens.length > 0" class="empty_span">-</span>
                <span v-else>{{ row.TypeCreateTime.substring(2, 16) }}</span>
              </template>
            </el-table-column>
            <el-table-column property="SettleTime" label="操作时间" width="120">
              <template slot-scope="{ row }">
                <el-tooltip v-if="row.FlagDel" effect="dark" :content="'撤回时间：' + row.RecallTime" placement="top-start">
                  <span>{{ row.SettleTime.substring(2, 16) }}</span>
                </el-tooltip>
                <span v-else-if="row.Childrens && row.Childrens.length > 0">{{ row.SettleTimeText }}</span>
                <span v-else>{{ row.SettleTime.substring(2, 16) }}</span>
              </template>
            </el-table-column>
            <el-table-column property="TypeDesc" width="70" label="类型" align="center" />
            <el-table-column property="OperationDesc" width="65" label="操作" align="center" />
            <el-table-column property="SettleTypeDesc" width="70" label="结算" />
            <el-table-column property="Payment" label="应收" align="left">
              <template slot-scope="{ row }">
                <span v-if="row.Childrens && row.Childrens.length > 0">-</span>
                <span v-else>￥{{ (row.TakeFee - row.RefundFee) | decimalFormat }}</span>
              </template>
            </el-table-column>
            <el-table-column property="Payment" label="实收" align="left">
              <template slot-scope="{ row }">
                ￥{{ row.SettleFee | decimalFormat }}
              </template>
            </el-table-column>
            <el-table-column property="Payment" label="欠款" align="left">
              <template slot-scope="{ row }">
                <span v-if="row.Childrens && row.Childrens.length > 0">-</span>
                <span v-else>￥{{ row.DebtFee | decimalFormat }}</span>
              </template>
            </el-table-column>
            <el-table-column property="TotalDebtFee" label="累计欠款">
              <template slot-scope="{ row }">
                ￥{{ row.TotalDebtFee | decimalFormat }}
              </template>
            </el-table-column>
          </el-table>
        </el-main>
        <el-footer height="60px" class="statistics">
          <div>￥{{ purchaser.bill.total.PastDebtFee | decimalFormat }}</div>
          <div>￥{{ (purchaser.bill.total.TodayTakeFee - purchaser.bill.total.TodayRefundFee) | decimalFormat }}</div>
          <div>￥{{ purchaser.bill.total.TodayRePayment | decimalFormat }}</div>
          <div>￥{{ purchaser.bill.total.DebtFee | decimalFormat }}</div>
          <div>期初欠款</div>
          <div>本期应收金额</div>
          <div>本期实收金额</div>
          <div>期末累计欠款</div>
        </el-footer>
      </el-container>
    </el-main>
    <el-aside width="510px">
      <cashier-detail ref="detail" source="bill" :dateRange="dateDebtRange" status="taked" @refresh="getPurList(true)" />
    </el-aside>
    <el-dialog v-dialog-drag title="新增欠款单" width="385px" :visible="purchaser.newTradeVisible"
      :close-on-click-modal="false" @close="purchaser.newTradeVisible = false">
      <el-form label-width="100px" v-model="purchaser.newTrade">
        <el-form-item label="客户：">
          {{ purchaser.newTrade.PurchaserName }}
        </el-form-item>
        <el-form-item label="欠款：">
          <el-input v-model="purchaser.newTrade.DebtFee" v-format.float /> 元
        </el-form-item>
        <el-form-item label="业务时间：">
          <el-date-picker v-model="purchaser.newTrade.CreateTime" value-format="yyyy-MM-dd" type="date"
            placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="purchaser.newTradeVisible = false">关闭</el-button>
        <el-button type="primary" size="small" @click="saveCreditTrade">保存</el-button>
      </div>
    </el-dialog>
    <purchaser-mapping ref="purchaserMappingDlg" @handleSearch="getPurList" />
  </el-container>
</template>
<script>
import { submit, exportFile } from '@/api/base';
import dayjs from "dayjs";
import { getDate, getDatePickerOptions } from '@/utils/tool';
import CashierDetail from './components/cashierDetail.vue';
import PurchaserMapping from "@/views/stall/trade/components/PurchaserMapping.vue";
import elTableInfiniteScroll from 'el-table-infinite-scroll';

export default {
  name: 'stallCashierBill',
  components: {
    PurchaserMapping,
    CashierDetail
  },
  directives: {
    'el-table-infinite-scroll': elTableInfiniteScroll
  },
  data() {
    return {
      dateDebtRange: '',
      datePickerOptions: getDatePickerOptions(),
      cashFlag: false,
      cashPayType: null,
      exportLoading: false,
      exportDetailLoading: false,
      timeOut: null,
      purchaser: {
        searchForm: {
          currentPage: 1,
          pageSize: 20,
          searchValue: '',
          debtFeeRange: { Key: '', Value: '' }
        },
        loading: false,
        total: {},
        list: [],
        selectRow: null,
        newTrade: {},
        newTradeVisible: false,
        repayParam: {},
        repayVisible: false,
        bill: {
          searchForm: {
            purchaserID: '',
            status: '',
            startTime: null,
            endTime: null,
            blockRecall: false,
            currentPage: 1,
            pageSize: 16
          },
          loading: false,
          total: {},
          list: [],
          selectRows: []
        },
      }
    };
  },
  mounted() {
    this.$nextTick(async () => {
      const today = getDate();
      this.dateDebtRange = [today, today];
      this.getPurList();
    });
  },
  methods: {
    handleRowClassName({ row }) {
      let className = [];
      if (row.FlagDel) {
        className = 'is-deleted';
      }
      if (row.Name && this.purchaser.selectRow == row) {
        className = 'is-selected';
      }
      if (row.TypeID && this.purchaser.bill.selectRows.includes(row)) {
        className = 'is-selected';
      }
      return className;
    },
    //#region 采购商
    initData() {
      this.purchaser.selectRow = null;
      this.purchaser.list = [];
      this.purchaser.total = {};
      this.purchaser.searchForm.currentPage = 1;
      this.purchaser.repayVisible = false;
      this.initBillData();
    },
    async getPurList(keepSelect = false) {
      let currentPage = keepSelect && this.purchaser.list > 0 ? this.purchaser.searchForm.currentPage : 1;
      let selectPurchaser = keepSelect && this.purchaser.selectRow ? this.purchaser.selectRow : null;
      this.initData();
      for (let i = 1; i <= currentPage; i++) {
        this.purchaser.searchForm.currentPage = i;
        await this.doGetPurList();
      }
      if (selectPurchaser) {
        let rows = this.purchaser.list.filter(t => t.PurchaserID == selectPurchaser.PurchaserID);
        if (rows.length > 0) {
          this.$refs.purchaser.setCurrentRow(rows[0]);
          this.purchaser.selectRow = rows[0];
          this.getBillList();
        }
      }
    },
    async exportData(type) {
      let param = {};
      let url = '';
      var that = this;
      const today = dayjs().startOf('day').format('YYYYMMDD');
      let excelName = '';
      if (type == 0) {
        excelName = '对账单' + '_' + today + '.xls';
        url = '/api/stallCashier/exportPurchaserList';
        param = { ...this.purchaser.searchForm };
        delete param.currentPage;
        delete param.pageSize;
        that.exportLoading = true;
      } else {
        if (!that.purchaser.selectRow) {
          that.$baseMessage(`请选择要导出的客户`, "error");
          return;
        }
        let purchaserName = (that.purchaser.selectRow.Nick ? '(' + that.purchaser.selectRow.Nick + ')' : '') + that.purchaser.selectRow.Name;
        excelName = purchaserName + '_' + '对账明细' + '_' + today + '.xls';
        param = { ...this.purchaser.bill.searchForm };
        param.purchaserID = that.purchaser.selectRow.PurchaserID;
        param.startTime = that.dateDebtRange && that.dateDebtRange.length > 0 ? that.dateDebtRange[0] : null;
        param.endTime = that.dateDebtRange && that.dateDebtRange.length > 1 ? that.dateDebtRange[1] : null;
        url = '/api/stallCashier/exportBillList';
        that.exportDetailLoading = true;
      }
      const { data } = await exportFile(url, param);
      const blob = new Blob([data.Blob], { type: "application/vnd.ms-excel" });
      var a = document.createElement("a");
      a.href = URL.createObjectURL(blob);
      a.download = excelName;
      a.style.display = "none";
      document.body.appendChild(a);
      a.click();
      a.remove();
      if (type == 0) {
        that.exportLoading = false;
      } else {
        that.exportDetailLoading = false;
      }
    },
    getPurNextList() {
      if (this.purchaser.list.length == 0 || !this.purchaser.total.count || this.purchaser.list.length >= this.purchaser.total.count) {
        return;
      }
      this.purchaser.searchForm.currentPage++;
      this.doGetPurList();
    },
    async doGetPurList() {
      this.purchaser.loading = true;
      const { data } = await submit('/api/stallCashier/getPurchaserList', this.purchaser.searchForm);
      this.purchaser.list = this.purchaser.list.concat(data.pageList.datas);
      this.purchaser.total = data.total;
      this.purchaser.total.count = data.pageList.total;
      this.purchaser.loading = false;
    },
    selectPurchaser(row) {
      this.purchaser.selectRow = row;
      this.getBillList();
    },
    //#endregion
    //#region 账单
    initBillData() {
      this.purchaser.bill.searchForm.currentPage = 1;
      this.purchaser.bill.total = {};
      this.purchaser.bill.list = [];
      this.purchaser.bill.selectRows = [];
    },
    getBillList() {
      this.initBillData();
      this.doGetBillList();
    },
    getNexBillList() {
      var list_count = 0;
      if (typeof (this.purchaser.bill.list) != 'undefined' && this.purchaser.bill.list != null && this.purchaser.bill.list.length > 0) {
        this.purchaser.bill.list.forEach(x => {
          if (x.HasChildren) {
            list_count = list_count + x.Childrens.length;
          } else {
            list_count++;
          }
        });
      }
      if (list_count == 0 || !this.purchaser.bill.total.count || list_count >= this.purchaser.bill.total.count) {
        return;
      }
      this.purchaser.bill.searchForm.currentPage++;
      this.doGetBillList();
    },
    async doGetBillList() {
      if (!this.purchaser.selectRow) {
        return;
      }
      this.purchaser.bill.searchForm.purchaserID = this.purchaser.selectRow.PurchaserID;
      this.purchaser.bill.searchForm.startTime = this.dateDebtRange && this.dateDebtRange.length > 0 ? this.dateDebtRange[0] : null;
      this.purchaser.bill.searchForm.endTime = this.dateDebtRange && this.dateDebtRange.length > 1 ? this.dateDebtRange[1] : null;
      this.purchaser.bill.searchForm.groupRepayByDate = true;
      this.purchaser.bill.loading = true;
      const { data } = await submit('/api/stallCashier/getBillList', this.purchaser.bill.searchForm);
      data.pageList.datas.forEach(item => item.showAmount = false);
      this.purchaser.bill.list = this.purchaser.bill.list.concat(data.pageList.datas);
      this.purchaser.bill.total = data.total;
      this.purchaser.bill.total.count = data.pageList.total;
      this.purchaser.bill.loading = false;
    },
    handleBillSelectedAll() {
      this.$refs.bill.toggleAllSelection();
    },
    handleBillSelectRow(selection, row) {
      var that = this;
      if (row.Childrens && row.Childrens.length > 0) {
        if (!selection.some(x => x.RowKey == row.RowKey)) {
          let data = [];
          selection.forEach(x => {
            if (row.Childrens.some(y => y.ID == x.ID)) {
              that.$refs.bill.toggleRowSelection(x, false);
            } else {
              data.push(x);
            }
          });
          selection = data;
        } else {
          row.Childrens.forEach(x => {
            if (x.Type != "credit" && !x.FlagDel && x.Operation != 'recall' && !selection.some(t => t.ID == x.ID)) {
              that.$refs.bill.toggleRowSelection(x, true);
              selection.push(x);
            }
          });
        }
      }
      this.handleBillSelectionChange(selection);
    },
    handleBillSelectionChange(selection) {
      var that = this;
      that.$nextTick(() => {
        //子节点全部取消，去除父节点选中
        selection.forEach(i => {
          if (i.Childrens && i.Childrens.length > 0) {
            let selected = false;
            i.Childrens.forEach(j => {
              if (selection.some(t => t.ID == j.ID)) {
                selected = true;
              }
            });
            if (!selected) {
              that.$refs.bill.toggleRowSelection(i, selected);
            }
          }
        });
        //子节点全部选中，选中父节点
        that.purchaser.bill.list.forEach(i => {
          if (i.Childrens && i.Childrens.length > 0 && !selection.some(x => x.RowKey == i.RowKey)) {
            let selectedCount = 0;
            i.Childrens.forEach(j => {
              if (selection.some(t => t.ID == j.ID)) {
                selectedCount++;
              }
            });
            if (selectedCount > 0 && selectedCount == i.Childrens.length) {
              that.$refs.bill.toggleRowSelection(i, true);
            }
          }
        });
      });
      //执行
      clearTimeout(that.timeOut);
      that.timeOut = setTimeout(() => {
        that.purchaser.bill.selectRows = selection;
        let onTrades = [];
        if (that.purchaser.bill.selectRows.length > 0) {
          that.purchaser.bill.selectRows.forEach(row => {
            if (!row.Childrens || row.Childrens.length <= 0) {
              onTrades.push({
                TradeIDs: row.Type != 'refund' ? row.TypeID : null,
                RefundIDs: row.Type == 'refund' ? row.TypeID : null,
                PurchaserID: that.purchaser.selectRow.PurchaserID,
                PurchaserName: that.purchaser.selectRow.Name,
                PurchaserNick: that.purchaser.selectRow.Nick,
                CreateTime: row.TypeCreateTime
              });
            }
          });
        }
        that.$refs.detail.fetchData({
          onTrades: onTrades,
          debtInfo: that.purchaser.bill.total
        });
      }, 100);
    },
    handleBillRowClick(row) {
      if (row.Type == "credit" || row.FlagDel || row.Operation == 'recall') {
        return;
      }
      var that = this;
      if (row.Childrens && row.Childrens.length > 0) {
        let selected = that.purchaser.bill.selectRows.some(x => x.RowKey == row.RowKey);
        row.Childrens.forEach(x => {
          if (x.Type != "credit" && !x.FlagDel && x.Operation != 'recall') {
            that.$refs.bill.toggleRowSelection(x, !selected);
          }
        });
        that.$refs.bill.toggleRowSelection(row, !selected);
      }
      else {
        that.$refs.bill.toggleRowSelection(row);
      }
    },
    //#endregion
    showCreditTradeEdit() {
      if (!this.purchaser.selectRow) {
        this.$baseMessage('请先选择客户！', 'error');
        return;
      }
      this.purchaser.newTrade = {
        PurchaserID: this.purchaser.selectRow.PurchaserID,
        PurchaserName: this.purchaser.selectRow.Name,
        PurchaserNick: this.purchaser.selectRow.Nick,
        CreateTime: '',
        DebtFee: ''
      };
      this.purchaser.newTradeVisible = true;
    },
    async saveCreditTrade() {
      if (!this.purchaser.newTrade) {
        return;
      }
      let debtFee = parseFloat(this.purchaser.newTrade.DebtFee);
      if (debtFee < 0) {
        this.$baseMessage('欠款必须大于0！', 'error');
        return;
      }
      if (!this.purchaser.newTrade.CreateTime) {
        this.$baseMessage('请选择业务时间！', 'error');
        return;
      }
      await submit('/api/stallCashier/saveCreditTrade', this.purchaser.newTrade);
      this.purchaser.newTrade = {};
      this.purchaser.newTradeVisible = false;
      this.getPurList(true);
    },
    showRepayDlg() {
      if (!this.purchaser.selectRow) {
        this.$baseMessage('请先选择客户！', 'error');
        return;
      }
      if (this.purchaser.selectRow.DebtFee == 0) {
        this.$baseMessage('欠款为0，无需还款', 'error');
        return;
      }
      let repayParam = {
        IsRepayTrade: false,
        PurchaserID: this.purchaser.selectRow.PurchaserID,
        PurchaserName: this.purchaser.selectRow.Name,
        PurchaserNick: this.purchaser.selectRow.Nick,
        DebtFee: this.purchaser.selectRow.DebtFee,
        ReceivePayment: '',
      };
      this.$refs.detail.$refs.repayDlg.show(repayParam);
    },
    //页面关联弹窗操作
    showPurchaserMapping(row) {
      this.$refs.purchaserMappingDlg.show(row);
    }
  },
};
</script>

<style lang="scss" scoped>
.cash-register-main {
  min-width: 1760px !important;
  height: 100%;
  background: #fff;
  display: flex;
  justify-content: space-between;

  .purchaser-info {
    .search-fee {
      width: 70px !important;
    }

    .search-name {
      width: 100px !important;
    }

    ::v-deep {
      .el-form-item {
        margin-right: 0;
      }
    }
  }

  ::v-deep {
    .empty_span {
      text-align: center;
      width: 70px;
      display: inline-block;
    }

    .el-table__indent {
      display: none;
    }

    .el-table__placeholder {
      display: inline-block;
      width: 10px;
    }

    .el-form-item {
      .el-select {
        width: 90px;
      }

      .el-input {
        max-width: 150px;
      }

      .el-date-editor--date {
        width: 150px !important;
      }

      .el-date-editor--daterange {
        width: 210px;

        .el-range__close-icon {
          width: 5px;
        }
      }

      .el-button {
        margin-left: 2px;
      }

      .el-form-item__label {
        padding: 0 2px;
      }
    }

    .el-table {
      .is-selected {
        background-color: rgb(224, 239, 255);
      }

      .is-selected:hover>td {
        background-color: rgb(224, 239, 255);
      }

      .is-deleted {
        color: red;
        text-decoration: line-through;

        .cell {
          color: #b5b5b5;
        }
      }
    }

    .el-table--fit::before {
      height: 0 !important;
    }
  }

  .statistics {
    text-align: right;
    font-size: 18px;
    font-weight: bold;
    line-height: 30px;

    &>div {
      width: 33%;
      display: inline-block;
      text-align: center;
    }
  }

  .record-list {
    .statistics {
      &>div {
        width: 25% !important;
      }
    }

    ::v-deep {
      .el-table {
        .el-table-column--selection {
          .cell {
            padding: 0 5px !important;
          }
        }
      }
    }
  }

  ::v-deep {
    .el-dialog__body {
      .money {
        font-size: 18px;
        font-family: 'fangsong', Courier, monospace;
        font-weight: bold;
        color: red;
      }

      .el-form {
        padding-right: 0px;
      }

      .el-form-item {
        .el-input {
          max-width: 150px;
        }

        .el-date-editor--date {
          width: 150px !important;
        }

        .el-button {
          margin-left: 2px;
        }

        .el-form-item__label {
          padding: 0 2px;
        }
      }
    }
  }
}
</style>
